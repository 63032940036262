.primaryFont {
    font-family: 'DM Sans', sans-serif;
}

.secondaryFont {
    font-family: 'DM Serif Display', serif;
}

.primaryColour {
    color: #e9d0ed;
}

.secondaryColour {
    color: #5d2e54;
}

.textColour {
    color: #0e0e0e;
}
