h1 {
    text-align: center;
    margin: 30px 0;
}

.contactForm {
    background-color: #e1dede;
    display: block;
    margin: 0 auto;
    width: 50%;
    height: fit-content;
    padding: 40px;
    border-radius: 30px;
}

.userDetails,
.userMessage {
    text-align: center;
}

input {
    border: none;
}

.detailInput,
.messageInput {
    margin: 20px 0;
    width: 60%;
    background-color: transparent;
    border-bottom: solid #5d2e54 2px;
    color: #5d2e54;
}

.messageInput {
    height: 100px;
}

.submitButtonContainer {
    display: flex;
    justify-content: center;
}

.submitButton {
    background-color: #5d2e54;
    color: #f5f5f5;
    border-radius: 8px;
    padding: 2px 20px;
}
